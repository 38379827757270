import axios from 'axios'

const root = '/api/helsten/'

const Endpoints = Object.freeze({
  Api: "db_sap_hana/test_api_status/",
  login:"auth-token/login/",
  logout:"auth-token/logout/",
  whoAmI:"db_sap_hana/whoami/",
  getCotation:"db_sap_hana/quotation/"
})

const Methods = Object.freeze({
  PUT: 'PUT',
  GET: 'GET',
  POST: 'POST',
  DELETE: 'DELETE',
  HEAD: 'HEAD',
  PATCH: 'PATCH'
})

class ApiRequestParameters {
  constructor (
    endpoint = Endpoints.Api,
    data = null,
    method = Methods.GET,
    urlParams = {},
    headers = {}
  ) {
    this.endpoint = endpoint
    this.data = data
    this.method = method
    this.urlParams = urlParams
    this.headers = headers
  }
}

async function getApiResponse (parameters) {
  const response = await axios({
    data: parameters.data,
    headers: parameters.headers,
    method: parameters.method,
    url: root + parameters.endpoint,
    withCredentials: true,
    params: parameters.urlParams
  })
  return response
}

export async function login (username, password) {
  const parameters = new ApiRequestParameters(
    Endpoints.login,
    {
      username: username,
      password: password
    },
    Methods.POST
  )
  return getApiResponse(parameters)
}

export async function getApiStatus (token) {
  const parameters = new ApiRequestParameters(
    Endpoints.Api,
    null,
    Methods.GET,
    {},
    {
      Authorization: `Token ${token}`
    }
  )

  return getApiResponse(parameters)
}

export async function logout (token) {
  const parameters = new ApiRequestParameters(
    Endpoints.logout,
    null,
    Methods.POST,
    {},
    {
      Authorization: `Token ${token}`
    }
  )

  return getApiResponse(parameters)
}

export async function whoAmI (token) {
  const parameters = new ApiRequestParameters(
    Endpoints.whoAmI,
    null,
    Methods.GET,
    {},
    {
      Authorization: `Token ${token}`
    }
  )

  return getApiResponse(parameters)
}


export async function getCotation (doc_entry, token) {
  
    const parameters = new ApiRequestParameters(
    Endpoints.getCotation,
    null,
    Methods.GET,
    {"doc_entry": doc_entry},
    {
      Authorization: `Token ${token}`
    }
  )


  return getApiResponse(parameters)
}