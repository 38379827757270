import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import { Button } from '@mui/material'
import TextField from '@mui/material/TextField'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import CircularProgress from '@mui/material/CircularProgress'
import { Navigate } from 'react-router-dom'
//Interfaces
//import { whoAmI, login } from '../../domain/user'
import image from './logo1.png'
import graphical from './graphical.png'
import { User } from '../../domain/User'

const Login = () => {
  const [sucess, setSucess] = useState('NONE')
  const [logged, setLogged] = useState('NONE')

  async function onSubmit () {
    setSucess('LOADING')
    const email = document.getElementById('usuario').value
    const password = document.getElementById('password').value
    const user = new User()
    const response = await user.login(email, password)
    setSucess(response.sucess)
    const me = await user.getIdentity()
    setLogged(me.sucess)
  }

  useEffect(() => {
    async function fetch () {
      const user = new User()
      const me = await user.getIdentity()
      setLogged(me.sucess)
    }
    fetch()
  }, [])

  return logged == true ? (
    <Navigate to={'/user'} />
  ) : (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '100vh',
        justifyContent: 'center'
      }}
      id='body'
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        id='loginBox'
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            Width: '30vw'
          }}
        >
          <img src={graphical} alt='graphical' height={300} width={300} />
        </Box>
        <Paper
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddin: '5%',
            justifyContent: 'center',
            margin: '1%',
            maxWidth: '30vw'
          }}
          component='form'
          data-testid='loginElement'
          id='loginElement'
        >
          <Box
            sx={{
              padding: '20px',
              borderRadius: '2rem'
            }}
          >
            <Box
              sx={{
                padding: '10px'
              }}
              data-testid='title'
              id='title'
            >
              <img src={image} alt='logo' height={100} width={250} />
            </Box>
          </Box>
          {sucess === true || sucess === 'LOADING' || sucess === 'NONE' ? (
            <></>
          ) : (
            <Alert
              severity='warning'
              data-testid='invalidCredentials'
              id='invalidCredentials'
            >
              <AlertTitle>Credenciais Inválidas</AlertTitle>
              Por favor confira o email e senha informados,{' '}
              <strong>
                se você está autorizado a acessar sua conta nesse horário,
              </strong>{' '}
              e tente novamente.
            </Alert>
          )}
          <TextField
            required
            id='usuario'
            data-testid='usuario'
            label='Usuário'
            type='text'
            sx={{ width: '90%', margin: '5%' }}
          />
          <TextField
            required
            id='password'
            data-testid='password'
            label='Senha'
            type='password'
            sx={{ width: '90%', margin: '5%' }}
          />
          <Button
            data-testid='login'
            id='login'
            onClick={onSubmit}
            disabled={sucess === 'LOADING'}
            variant='contained'
            sx={{ width: '90%', margin: '5%' }}
          >
            {sucess === 'LOADING' ? <CircularProgress size={20} /> : 'LOGIN'}
          </Button>
        </Paper>
      </Box>
    </Box>
  )
}

export default Login
