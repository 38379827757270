export const sampleCotacao = {
  pessoaDeContato: '-',
  numeroDeDocumento: '-',
  dataDeValidade: '-',
  dataDeLancamento: '-',
  vendedorExterno: '-',
  classificacaoABC: '-',
  status: '-',
  cidadeDeFaturamento: '-',
  estadoDeFaturamento: '-',
  condicaoDePagamento: '-',
  usoPrincipal: '-',
  impostos: [
    
  ],
  itens: [
    
  ]
}
