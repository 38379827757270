import React, { useState, useEffect } from 'react'
import { Cotacao } from '../../domain/Cotacao'
import {
  getDocEntryFromInfo,
  resize
} from '../../services/bitrixEnviromentInterface'
import { sampleCotacao } from './SampleCotacao'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import image from './logo.png'
import AppBar from '@mui/material/AppBar'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'

const CotacaoComp = () => {
  const [cotacao, setCotacao] = useState(sampleCotacao)

  useEffect(() => {
    async function fetch () {
      resize()
      const doc_entry = await getDocEntryFromInfo()
      const cotacao = new Cotacao(doc_entry)
      const response = await cotacao.getCotation()
      if (response.sucess) {
        setCotacao(response.data)
      }
    }
    fetch()
  }, [])

  useEffect(() => {
    console.log(cotacao)
  }, [cotacao])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%'
      }}
    >
      <AppBar
          sx={{
            backgroundColor: '#e8e8e8'
          }}
          position='static'
        >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <CardContent
                sx={{
                  maxWidth: '70%',
                  overflow: 'scroll'
                }}
              >
                <Box
                  sx={{
                    width: '90%'
                  }}
                  data-testid='title'
                  id='title'
                >
                  <img src={image} alt='logo' height={100} width={250} />
                </Box>
              </CardContent>
            </Box>
        </AppBar>
      <Box
        sx={{
          width: '90%',
          padding: '10px',
          overflow: 'hidden',
          margin: '5px'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <Box
            sx={{
              width: '50%',
              overflow: 'hidden',
              margin: '5px'
            }}
          >
            <Typography
              sx={{ fontSize: 14 }}
              color='text.secondary'
              gutterBottom
            >
              Número do Documento
            </Typography>
            <Typography variant='h5' component='div'>
              {cotacao.numeroDeDocumento}
            </Typography>
            <Typography
              sx={{ fontSize: 14 }}
              color='text.secondary'
              gutterBottom
            >
              Pessoa de Contato
            </Typography>
            <Typography variant='h5' component='div'>
              {cotacao.pessoaDeContato}
            </Typography>
            <Typography
              sx={{ fontSize: 14 }}
              color='text.secondary'
              gutterBottom
            >
              Vendedor Externo
            </Typography>
            <Typography variant='h5' component='div'>
              {cotacao.vendedorExterno}
            </Typography>
            <Typography
              sx={{ fontSize: 14 }}
              color='text.secondary'
              gutterBottom
            >
              Uso Principal
            </Typography>
            <Typography variant='h5' component='div'>
              {cotacao.usoPrincipal}
            </Typography>
            <Typography
              sx={{ fontSize: 14 }}
              color='text.secondary'
              gutterBottom
            >
              Status
            </Typography>
            <Typography variant='h5' component='div'>
              {cotacao.status}
            </Typography>
            <Typography
              sx={{ fontSize: 14 }}
              color='text.secondary'
              gutterBottom
            >
              Condição de Pagamento
            </Typography>
            <Typography variant='h5' component='div'>
              {cotacao.condicaoDePagamento}
            </Typography>
          </Box>
          <Box
            sx={{
              width: '50%',
              overflow: 'hidden',
              margin: '5px'
            }}
          >
            <Typography
              sx={{ fontSize: 14 }}
              color='text.secondary'
              gutterBottom
            >
              Cidade de Faturamento
            </Typography>
            <Typography variant='h6' component='div'>
              {cotacao.cidadeDeFaturamento}
            </Typography>
            <Typography
              sx={{ fontSize: 14 }}
              color='text.secondary'
              gutterBottom
            >
              Estado de Faturamento
            </Typography>
            <Typography variant='h6' component='div'>
              {cotacao.estadoDeFaturamento}
            </Typography>
            <Typography
              sx={{ fontSize: 14 }}
              color='text.secondary'
              gutterBottom
            >
              Data de Lançamento
            </Typography>
            <Typography variant='h6' component='div'>
              {cotacao.dataDeLancamento}
            </Typography>
            <Typography
              sx={{ fontSize: 14 }}
              color='text.secondary'
              gutterBottom
            >
              Data de Validade
            </Typography>
            <Typography variant='h6' component='div'>
              {cotacao.dataDeValidade}
            </Typography>
            <Typography
              sx={{ fontSize: 14 }}
              color='text.secondary'
              gutterBottom
            >
              Classificação ABC
            </Typography>
            <Typography variant='h6' component='div'>
              {cotacao.classificacaoABC}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          overflowY: 'auto',
          width: '95%',
          margin: '5px'
        }}
      >
        {cotacao.impostos.map((item, index) => {
          return (
            <Paper
              sx={{
                padding: '5px',
                margin: '5px'
              }}
            >
              <Typography
                sx={{ fontSize: 14 }}
                color='text.secondary'
                gutterBottom
              >
                {item.nome}
              </Typography>
              <Typography variant='h6' component='div'>
                R${item.valor}
              </Typography>
            </Paper>
          )
        })}
      </Box>
      {cotacao.itens.map((item, index) => {
        return (
          <Paper
            sx={{
              width: '90%',
              padding: '10px',
              overflow: 'hidden',
              margin: '5px'
            }}
          >
            <Typography variant='h4' component='div'>
              {item.codigo}
            </Typography>
            <Typography variant='h6' component='div'>
              {item.descricao}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',

                width: '100%'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  width: '50%'
                }}
              >
                <Typography
                  sx={{ fontSize: 14 }}
                  color='text.secondary'
                  gutterBottom
                >
                  Catálogo do PN
                </Typography>
                <Typography variant='h6' component='div'>
                  {item.catalogoDoPN}
                </Typography>
                <Typography
                  sx={{ fontSize: 14 }}
                  color='text.secondary'
                  gutterBottom
                >
                  NCM
                </Typography>
                <Typography variant='h6' component='div'>
                  {item.ncm}
                </Typography>
                <Typography
                  sx={{ fontSize: 14 }}
                  color='text.secondary'
                  gutterBottom
                >
                  Motivo de Encerramento
                </Typography>
                <Typography variant='h6' component='div'>
                  {item.motivoDeEncerramento}
                </Typography>
                <Typography
                  sx={{ fontSize: 14 }}
                  color='text.secondary'
                  gutterBottom
                >
                  Nº do Pedido do CLiente
                </Typography>
                <Typography variant='h6' component='div'>
                  {item.numeroPedidoDoCliente}
                </Typography>
                <Typography
                  sx={{ fontSize: 14 }}
                  color='text.secondary'
                  gutterBottom
                >
                  Nº da Linha do Pedido do Cliente
                </Typography>
                <Typography variant='h6' component='div'>
                  {item.numeroDaLinhaDoPedidoDoCliente}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  width: '50%'
                }}
              >
                <Typography
                  sx={{ fontSize: 14 }}
                  color='text.secondary'
                  gutterBottom
                >
                  QTD
                </Typography>
                <Typography variant='h6' component='div'>
                  {item.quantidade}
                </Typography>
                <Typography
                  sx={{ fontSize: 14 }}
                  color='text.secondary'
                  gutterBottom
                >
                  Preço Unitário
                </Typography>
                <Typography variant='h6' component='div'>
                  R$ {item.precoUnitario}
                </Typography>
                <Typography
                  sx={{ fontSize: 14 }}
                  color='text.secondary'
                  gutterBottom
                >
                  Custo
                </Typography>
                <Typography variant='h6' component='div'>
                  R$ {item.custo}
                </Typography>
                <Typography
                  sx={{ fontSize: 14 }}
                  color='text.secondary'
                  gutterBottom
                >
                  Vendedor Interno
                </Typography>
                <Typography variant='h6' component='div'>
                  {item.vendedorInterno}
                </Typography>
                <Typography
                  sx={{ fontSize: 14 }}
                  color='text.secondary'
                  gutterBottom
                >
                  Formação de Preço
                </Typography>
                <Typography variant='h6' component='div'>
                  {item.formacaoDePreco}
                </Typography>
              </Box>
            </Box>
            <hr></hr>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',

                width: '100%'
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  width: '50%'
                }}
              >
                <Typography
                  sx={{ fontSize: 14 }}
                  color='text.secondary'
                  gutterBottom
                >
                  Quantidade Mínima
                </Typography>
                <Typography variant='h7' component='div'>
                  {item.quantidadeMinima}
                </Typography>
                <Typography
                  sx={{ fontSize: 14 }}
                  color='text.secondary'
                  gutterBottom
                >
                  Quantidade Disponível
                </Typography>
                <Typography variant='h7' component='div'>
                  {item.quantidadeDisponivel}
                </Typography>
                <Typography
                  sx={{ fontSize: 14 }}
                  color='text.secondary'
                  gutterBottom
                >
                  Status ILV
                </Typography>
                <Typography variant='h7' component='div'>
                  {item.ilvStatus}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-start',
                  width: '50%'
                }}
              >
                <Typography
                  sx={{ fontSize: 14 }}
                  color='text.secondary'
                  gutterBottom
                >
                  Lead Time
                </Typography>
                <Typography variant='h7' component='div'>
                  {item.leadTime}
                </Typography>
                <Typography
                  sx={{ fontSize: 14 }}
                  color='text.secondary'
                  gutterBottom
                >
                  Data de Entrega
                </Typography>
                <Typography variant='h7' component='div'>
                  {item.dataDeEntrega}
                </Typography>
                <Typography
                  sx={{ fontSize: 14 }}
                  color='text.secondary'
                  gutterBottom
                >
                  Utilização
                </Typography>
                <Typography variant='h7' component='div'>
                  {item.utilizacao}
                </Typography>
              </Box>
            </Box>
          </Paper>
        )
      })}
    </Box>
  )
}

export default CotacaoComp
